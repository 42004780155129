import { useEffect } from "react";
import "./App.css";

function App() {
  
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "https://discord.gg/SApAvjhw9t";
    }, 10 * 1000);

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, []);

  return (
    <div className="App">
      <article>
        <h1>Esta página está em manutenção</h1>
        <p>Pedimos desculpas por qualquer inconveniente.</p>
        <p>Fique atento ás novidades no nosso discord.</p>
        <p id="signature">
          &mdash; <a href="https://discord.gg/SApAvjhw9t">SoftConnection</a>
        </p>
        <h3>Irá ser reendirecionado para o nosso discord em 10 segundos.</h3>
      </article>
    </div>
  );
}

export default App;
